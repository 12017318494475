@font-face {
  font-family: 'Bungee';
  src: local('Bungee'), url(fonts/Bungee-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Karla-Regular';
  src: local('Karla-Regular'), url(fonts/Karla-VariableFont_wght.ttf) format('truetype');
}

.defaultLayout {
  color: white;
  background-image: linear-gradient(-501deg, rgba(147,107,165,0.98) 0%, #5646A5 67%);
}
.md-toolbar {
  padding: 0 16px;
  flex-flow: row wrap;
  position: relative;
  z-index: 2;
  margin: 0;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}

.hero-intro {
  font-family: 'Bungee', cursive;
  font-size: 40px;
  text-align: center;
  line-height: 1.7em;
}

.divider {
  width: 443px;
  height: 3px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  background-image: linear-gradient(-90deg, #FF7D5D 40%, #FF5B61 97%);
  border-radius: 8px;
}

.md-toolbar .md-button:first-child {
    margin-left: 0;
}

.md-toolbar .md-button, .md-toolbar .md-icon {
    z-index: 1;
}

.md-button:not([disabled]) {
    cursor: pointer;
}

.md-button {
  min-width: 88px;
  height: 36px;
  margin: 6px 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

*, :after, :before {
    box-sizing: inherit;
}

.md-body-1, body {
    font-weight: 400;
    line-height: 20px;
}

.md-button-clean {
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
  outline: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  transition: .4s cubic-bezier(.4,0,.2,1);
  font-family: inherit;
  line-height: normal;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  color: white !important;
}

.md-button-content {
  position: relative;
  z-index: 2;
}

.subText {
  font-family: Karla-Regular;
  font-size: 23px;
  color: #FFFFFF;
  text-align: center;
}

.signUp {
  font-size: 30px;
  float:left;
}

.md-layout {
  height: 100vh;
}

.md-layout-item {
  padding-top: 80px;

  &:nth-child(1) {
    background-color: #1D1D27;
  }

  &:nth-child(2) {
    background-color: #1D1D27;
  }
}

.title {
  font-family: 'Bungee', cursive;
  font-size: 30px;
  font-weight: initial;
  letter-spacing: .25px;
  margin: 0;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
  line-height: 26px;
  padding-top: 40px;
}

@media screen and (max-width: 600px) {
  #title {
    visibility: hidden;
    display: none;
  }
  #subLinks {
    visibility: hidden;
    display: none;
  }
}

.md-button {
  color: white;
}
svg {
  position: absolute;
  top: -20%;left: 9%;
  transform: translate(-50%, -50%);
}
path {
  fill: #363a3b;
}
